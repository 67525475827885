import React from "react";
import Navbar from "../About-us/Navbar/navbar";
import Floating from "../Floating icon/floating";
import Footer from "../../sections/Footer/Footer";
import "./Forrester_report.css";
import ContactUsForm from "../SwitchToMac/ContactUsForm";
import PDFViewer from "../../sections/PDFViewer/PDFViewer";
import Breadcrum from "../../sections/Breadcrum/Breadcrum";

import ForresterReportPDF from "../../assets/data/Forrester_report.pdf";
import { Helmet } from "react-helmet-async";

function ForresterReport() {
  return (
    <>
      <Helmet>
        <title>
          Total Economic Impact of Apple Devices for K-12 Education | Datalogics
          India
        </title>
        <meta
          name="description"
          content="Discover how Apple devices deliver cost savings, efficiency, and learning benefits for K-12 education. Explore the Total Economic Impact™️ study to learn how iPads and MacBooks create long-term value for schools."
        />
        <link
          rel="canonical"
          href={"https://www.datalogicsindia.com/forrester-report"}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            "Total Economic Impact of Apple Devices for K-12 Education | Datalogics India"
          }
        />
        <meta
          property="og:url"
          content={"https://www.datalogicsindia.com/forrester-report"}
        />
        <meta
          property="og:site_name"
          content="Total Economic Impact of Apple Devices for K-12 Education | Datalogics India"
        />
        <meta
          name="twitter:title"
          content={`Total Economic Impact of Apple Devices for K-12 Education | Datalogics India`}
        />
        <meta name="twitter:site" content="@datalogicsindia" />
        <meta name="twitter:creator" content="@Datalogics_IN" />
      </Helmet>
      <Navbar />
      <Floating />
      <div className="container mt-5">
        <div className="row pt-3 pt-lg-5">
          <Breadcrum textColor={"#000000"} />
        </div>
      </div>
      <PDFViewer
        doc={ForresterReportPDF}
        title="Apple Devices for Education Report"
        filename="Apple Devices for Education Report"
      />
      <ContactUsForm
        ctaMain="Contact us!"
        ctaPara="Explore and find the most effective solution tailored to meet the unique needs of your business."
        subject="Forrester Report page Form Response"
      />
      <Footer />
    </>
  );
}

export default ForresterReport;
