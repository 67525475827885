import React, { useState } from "react";
import "./FormLead.css";

// import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

import Navbar from "../About-us/Navbar/navbar";
import Footer from "../pages/vector/commonfooter/footer";
import axios from "axios";
// import IIM from '../../assets/data/IIM.pdf'

function FormLead() {
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [institute, setInstitute] = useState(null);
  const [showPDF, setShowPDF] = useState(false);

  // const [errors, setErrors] = useState({});

  const updateNumber = (value) => {
    const re = /^[0-9\b]{0,10}$/;
    // if value is not blank, then test the regex

    if (value === "" || re.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // const newErrors = validateForm(name,email,phoneNumber,institute);
    // setErrors(newErrors);

    if (name !== "" && email !== "" && phoneNumber !== "" && institute !== "") {
      console.log("Email Send Successfully", name);
      const headers = {
        "Content-Type": "application/json",
        "api-key":
          "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",
      };

      const emailData = {
        name: "IIM Form response",
        sender: {
          email: email,
          name: "IIM Form response",
        },
        to: [
          {
            email: "meerabee@datalogicsindia.com",
          },
        ],
        subject: "IIM form response",
        htmlContent: `<html><body> <p>Name : ${name} </p>  <br /> <p>Email : ${email} </p>  <br /><p>Number : ${phoneNumber} </p> <br /><p>IIM Location : ${institute} </p> </body></html>`,
        headers: {
          "X-Mailin-custom":
            "custom_header_1:custom_value_1|custom_header_2:custom_value_2|custom_header_3:custom_value_3",
          charset: "iso-8859-1",
        },
      };

      axios
        .post(
          "https://api.brevo.com/v3/smtp/email",
          JSON.stringify(emailData),
          {
            headers: headers,
          }
        )
        .then((response) => {
          console.log("send", response);
          toast.success("Request Submitted!");
          setShowPDF(true);
        })
        .catch((err) => console.log(err));
    }

    setName("");
    setEmail("");
    setPhoneNumber("");
    setInstitute("");
  };

  //   const validateForm = (name,email,phoneNumber,institute) => {
  //     const errors = {};

  //     if (!name.trim()) {
  //         errors.name = 'Username is required';
  //     }

  //     if (!email.trim()) {
  //         errors.email = 'Email is required';
  //     } else if (!/\S+@\S+\.\S+/.test(email)) {
  //         errors.email = 'Email is invalid';
  //     }

  //     if (!phoneNumber.trim()) {
  //         errors.phoneNumber = 'Phone Number is required';
  //     }

  //     if (!institute.trim()) {
  //         errors.institute = 'institute is required';
  //     }

  //     return errors;
  // };

  const docs = [
    {
      uri: require("../../assets/data/IIM.pdf"),
      fileType: "pdf",
      fileName: "IIM Offers Datalogics India",
    }, // Local File
  ];

  return (
    <>
      <Navbar />
      <ToastContainer position="top-right" />
      {!showPDF && (
        <div className="formContainer container mt-5 pt-5 mb-5">
          <h2>Unlock Exclusive Offers! Fill Out the Form Below</h2>
          <form>
            <input
              type="text"
              name="Name"
              id=""
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {/* {errors.name &&
                        <span className="error-message">
                            {errors.name}
                        </span>
                } */}
            <input
              type="email"
              name="Email"
              id=""
              placeholder="Your email ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="tel"
              name="Phone Number"
              id=""
              placeholder="Your Mobile Number"
              value={phoneNumber}
              onChange={(event) => updateNumber(event.target.value)}
              required
            />
            <input
              type="text"
              name="Institution"
              id=""
              placeholder="IIM Location"
              value={institute}
              onChange={(e) => setInstitute(e.target.value)}
              required
            />
            <input type="submit" value="Submit" onClick={handleSubmit} />
          </form>
        </div>
      )}

      {showPDF && (
        <div className="mt-5 pt-5 mb-5 PDFViewermain">
          <div className="PDFViewer container mb-3">
            {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={IIM} defaultScale={SpecialZoomLevel.PageFit} />
            </Worker> */}
            <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}

export default FormLead;
