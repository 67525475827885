import React from "react";
import Navbar from "../About-us/Navbar/navbar";
import Floating from "../Floating icon/floating";
import Footer from "../../sections/Footer/Footer";
import "./AppleEducationSmartInvestment.css";
import ContactUsForm from "../SwitchToMac/ContactUsForm";
import PDFViewer from "../../sections/PDFViewer/PDFViewer";
import Breadcrum from "../../sections/Breadcrum/Breadcrum";

import AppleEduSmartInvestmentPDF from "../../assets/data/Apple_Education_Smart_Investment.pdf";
import { Helmet } from "react-helmet-async";

function AppleEducationSmartInvestment() {
  return (
    <>
      <Helmet>
        <title>
          Apple in Education: Smart Investment | iPad for Students and Teachers
        </title>
        <meta
          name="description"
          content="Discover why iPad is a smart investment for education. From lower total cost of ownership to long-term value, explore how Apple devices deliver a better ROI for schools and colleges. Read the full document to learn more."
        />
        <link
          rel="canonical"
          href={
            "https://www.datalogicsindia.com/Apple-Education-Smart-Investment-report"
          }
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            "Apple in Education: Smart Investment | iPad for Students and Teachers"
          }
        />
        <meta
          property="og:url"
          content={
            "https://www.datalogicsindia.com/Apple-Education-Smart-Investment-report"
          }
        />
        <meta
          property="og:site_name"
          content="Apple in Education: Smart Investment | iPad for Students and Teachers"
        />
        <meta
          name="twitter:title"
          content={`Apple in Education: Smart Investment | iPad for Students and Teachers`}
        />
        <meta name="twitter:site" content="@datalogicsindia" />
        <meta name="twitter:creator" content="@Datalogics_IN" />
      </Helmet>
      <Navbar />
      <Floating />
      <div className="container mt-5">
        <div className="row pt-3 pt-lg-5">
          <Breadcrum textColor={"#000000"} />
        </div>
      </div>
      <PDFViewer
        doc={AppleEduSmartInvestmentPDF}
        title="Apple Education Smart Investment Report"
        filename="Apple Education Smart Investment Report"
      />
      <ContactUsForm
        ctaMain="Contact us!"
        ctaPara="Explore and find the most effective solution tailored to meet the unique needs of your business."
        subject="Apple Education Smart Investment report page Form Response"
      />
      <Footer />
    </>
  );
}

export default AppleEducationSmartInvestment;
