import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import axios from "axios";

import Styles from "./LeadForm.module.css";

function LeadForm({ leadFormModal, setLeadFormModal, docLink, docName }) {
  const [name, setName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [userOrganization, setUserOrganization] = useState("");
  const [agree, setAgree] = useState(false);

  const updateNumber = (value) => {
    const re = /^[0-9\b]{0,10}$/;
    // if value is not blank, then test the regex

    if (value === "" || re.test(value)) {
      setUserNumber(value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    setName("");
    setUserEmail("");
    setUserNumber("");
    setUserOrganization("");
    setAgree(false);

    console.log("Email Send Successfully", name);
    const headers = {
      "Content-Type": "application/json",
      "api-key":
        "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",
    };

    const emailData = {
      name: `TCO ${docName} Form response`,
      sender: {
        email: userEmail,
        name: name,
      },
      to: [
        {
          email: "marcom@datalogicsindia.com",
        },
      ],
      subject: `Someone downloaded the ${docName}`,
      htmlContent: `<html>
                            <body> 
                                <p>Name : ${name} </p> 
                                <br />
                                <p>Email : ${userEmail} </p>
                                <br /><p>Number : ${userNumber} </p>  
                                <br />
                                <p>Organization : ${userOrganization} </p> 
                                <br />
                                <p>I agree to receive updates on services, events and exciting projects : ${
                                  agree ? "yes" : "no"
                                } </p>
                            </body>
                        </html>`,
      headers: {
        "X-Mailin-custom":
          "custom_header_1:custom_value_1|custom_header_2:custom_value_2|custom_header_3:custom_value_3",
        charset: "iso-8859-1",
      },
    };

    axios
      .post("https://api.brevo.com/v3/smtp/email", JSON.stringify(emailData), {
        headers: headers,
      })
      .then((response) => {
        console.log("send", response);
        window.open(`${docLink}`, "_blank");
        setLeadFormModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Modal
      show={leadFormModal}
      size="lg"
      onHide={() => setLeadFormModal(false)}
      centered
    >
      <Modal.Body className={Styles.reportLinkModal}>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          style={{ position: "absolute", top: "10px", right: "10px" }}
          onClick={() => setLeadFormModal(false)}
        ></button>
        <div className="text-container">
          <h2 className="text-center text-black">Get the Full {docName}</h2>
          {/* <p className='text-center'>Don’t miss out on the insights that matter. Fill out a quick form to get the complete Skoch report—your guide to smarter decisions and strategic moves.</p> */}
        </div>
        <form
          onSubmit={handleSubmit}
          className="d-flex flex-wrap align-items-center justify-content-start"
        >
          <input
            type="text"
            name="Name"
            id="Name"
            placeholder="Name"
            onChange={(event) => setName(event.target.value)}
            value={name}
            required
          />
          <input
            type="email"
            name="Email"
            id="Email"
            placeholder="Email"
            onChange={(event) => setUserEmail(event.target.value)}
            value={userEmail}
            required
          />
          <input
            type="tel"
            name="Mobile"
            id="Mobile"
            placeholder="Mobile"
            onChange={(event) => updateNumber(event.target.value)}
            value={userNumber}
            required
          />
          <input
            type="text"
            name="Organization"
            id="Organization"
            placeholder="Organization"
            onChange={(event) => setUserOrganization(event.target.value)}
            value={userOrganization}
            required
          />
          <div className={Styles.checkboxMarketing}>
            <input
              type="checkbox"
              name="yes"
              id="yes"
              value={agree}
              onChange={() => setAgree(!agree)}
            />
            <label htmlFor="yes">
              Yes, I agree to receive updates on services, events and exciting
              projects
            </label>
          </div>
          <input type="submit" value="Submit" />
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default LeadForm;
