import React, { Suspense } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import AOS from "aos";
import "aos/dist/aos.css";

import ScrollToTop from "./customHooks/ScrollToTop";
import Loading from "./components/Loading/Loading";
import IPadProM4 from "./components/intro-page/IPadProM4";
import MacbookProM3 from "./components/intro-page/MacbookProM3";
import AppointmentCalender from "./components/Appointment-Calender/AppointmentCalender";
import FormLead from "./components/FormLead/FormLead";
import Profile from "./components/Profile/Profile";
import SkochReport from "./components/SkochReport/SkochReport";
import CanalysReport from "./components/canalysReport/CanalysReport";
import Sitemap from "./Sitemap";
import Copilotmailer from "./components/intro-page/Copilotmailer";

const IntroPage = React.lazy(() => import("./components/intro-page/IntroPage"));
const Peoples = React.lazy(() =>
  import("./components/Peoples-page/mainpage/PeoplesMainpage")
);
const ContactUs = React.lazy(() =>
  import("./components/Contact-Us-page/contact-us")
);
const PrivacyPolicy = React.lazy(() =>
  import("./components/Privacy-Policy/PrivacyPolicy")
);
const MissingRoute = React.lazy(() =>
  import("./components/intro-page/MissingRoute")
);
const NotFound = React.lazy(() => import("./components/Not-Found/NotFound"));

const ServiceMainpage = React.lazy(() =>
  import("./components/Service-page/mainpage/mainpage")
);
const ServiceEnterprise = React.lazy(() =>
  import("./components/Service-page/ServiceEnterprise/ServiceEnterprise")
);
const ServiceRepairs = React.lazy(() =>
  import("./components/Service-page/ServiceRepairs/ServiceRepairs")
);
const ServiceLogistics = React.lazy(() =>
  import("./components/Service-page/ServiceLogistics/ServiceLogistics")
);
const ServiceDcare = React.lazy(() =>
  import("./components/Service-page/ServiceDcare/ServiceDcare")
);
const ServiceLCM = React.lazy(() =>
  import("./components/Service-page/ServiceLCM/ServiceLCM")
);

const Educationmainpage = React.lazy(() =>
  import("./components/Education-page/mainpage/mainpage")
);
// const Educationmainpage = React.lazy(() => import("./components/Education-page/mainpageEdu/mainpage"));
const K12 = React.lazy(() => import("./components/Education-page/K12/K12"));
const Teachers = React.lazy(() =>
  import("./components/Education-page/Teachers/Teachers")
);
const Leaders = React.lazy(() =>
  import("./components/Education-page/Leaders/Leaders")
);
const HiEducation = React.lazy(() =>
  import("./components/Education-page/Hi-Education/Hi-Education")
);
const IT = React.lazy(() => import("./components/Education-page/IT/IT"));
const DCareSchool = React.lazy(() =>
  import("./components/Education-page/DCare-school/DCare-school")
);
const DTrain = React.lazy(() =>
  import("./components/Education-page/DTrain/DTrain")
);
const DEP = React.lazy(() =>
  import("./components/Education-page/DEP/DEP-main")
);
const Parents = React.lazy(() =>
  import("./components/Education-page/Parents/Parents")
);
const CaseStudy = React.lazy(() =>
  import("./components/Education-page/Case-Study/CaseStudy")
);
const ServiceEdu = React.lazy(() =>
  import("./components/Education-page/Service/serviceEdu")
);

const EnterpriceMainpage = React.lazy(() =>
  import("./components/Enterprise-page/mainpage/mainpage")
);
const Bep = React.lazy(() => import("./components/Enterprise-page/BEP/Bep"));
const MDM = React.lazy(() => import("./components/Enterprise-page/MDM/MDM"));
const EnterpriseSerices = React.lazy(() =>
  import("./components/Enterprise-page/EnterpriseService/EnterpriseServise")
);
const Dep = React.lazy(() => import("./components/Enterprise-page/DEP/Dep"));
const Techseries = React.lazy(() =>
  import("./components/Enterprise-page/Techseries/Techseries")
);
const AddOns = React.lazy(() =>
  import("./components/Enterprise-page/AddOns/AddOns")
);
const LargeEnterprise = React.lazy(() =>
  import("./components/Enterprise-page/LargeEnterprise/LargeEnterprise")
);
const MidMarket = React.lazy(() =>
  import("./components/Enterprise-page/MidMarket/MidMarket")
);
const ABM = React.lazy(() => import("./components/Enterprise-page/ABM/ABM"));
const Developers = React.lazy(() =>
  import("./components/Enterprise-page/Developers/Developers")
);
const StartUpsSME = React.lazy(() =>
  import("./components/Enterprise-page/Start-Ups/StartUpsSME")
);

const Mainpage = React.lazy(() =>
  import("./components/pages/vector/mainpage/mainpage")
);
const Training = React.lazy(() =>
  import("./components/pages/vector/Training/training")
);
const Buildings = React.lazy(() =>
  import("./components/pages/vector/Buldings/buildings")
);
const Entertainment = React.lazy(() =>
  import("./components/pages/vector/Entertinment/Entertainment")
);
const Landscap = React.lazy(() =>
  import("./components/pages/vector/Landscap/Landscap")
);

const BlogHome = React.lazy(() => import("./components/Blog/BlogHome"));
const BlogPage = React.lazy(() => import("./components/Blog/page/BlogPage"));

const Switcher = React.lazy(() => import("./components/SwitchToMac/Switcher"));
const MacDoesThat = React.lazy(() =>
  import("./components/MacDoesThat/MacDoesThat")
);
// const Refresh = React.lazy(() => import("./components/RefreshYourMac/Refresh"));

const CopilotPlusPCs = React.lazy(() =>
  import("./components/CopilotPlusPCs/CopilotPlusPCs")
);

// const Gallery = React.lazy(() => import("./components/Gallery/Gallery"));

const LinkTree = React.lazy(() => import("./components/LinkTree/LinkTree"));
const FeedbackForm = React.lazy(() =>
  import("./components/FeedbackForm/FeedbackForm")
);
const MumbaiEventContact = React.lazy(() =>
  import("./components/Event-Mum-Contact/Event-Mum-Contact")
);

AOS.init();

function App() {
  if (
    !window.location.host.startsWith("localhost") &&
    !window.location.host.startsWith("192")
  ) {
    if (!window.location.host.startsWith("www")) {
      window.location.replace(
        window.location.protocol +
          "//www." +
          window.location.host +
          window.location.pathname
      );
      // eslint-disable-next-line no-undef
      return null;
    }
  }

  if (
    window.location.pathname === "/switch-to-mac" ||
    window.location.pathname === "/switch-to-mac/"
  ) {
    window.location.href = "/enterprise/switch-to-mac";
  }

  if (
    window.location.pathname === "/vector" ||
    window.location.pathname === "/vector/"
  ) {
    window.location.href = "/vectorworks";
  }

  if (
    window.location.pathname === "/vector/Buildings" ||
    window.location.pathname === "/vector/Buldings/"
  ) {
    window.location.href = "/vectorworks/Buildings";
  }

  if (
    window.location.pathname === "/vector/Landscap" ||
    window.location.pathname === "/vector/Landscap/"
  ) {
    window.location.href = "/vectorworks/Landscap";
  }

  if (
    window.location.pathname === "/vector/Entertainments" ||
    window.location.pathname === "/vector/Entertainments/"
  ) {
    window.location.href = "/vectorworks/Entertainments";
  }

  if (
    window.location.pathname === "/vector/Training" ||
    window.location.pathname === "/vector/Training/"
  ) {
    window.location.href = "/vectorworks/Training";
  }

  if (window.location.pathname === "/dcare") {
    window.location.href = "/service/dcare";
  }

  return (
    <HelmetProvider>
      <Router>
        <Suspense fallback={<Loading />}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<IntroPage />} />
            <Route path="*" element={<MissingRoute />} />
            <Route path="/not-found" element={<NotFound />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/people" element={<Peoples />} />
            <Route path="/service" element={<ServiceMainpage />} />
            <Route path="/service/enterprise" element={<ServiceEnterprise />} />
            <Route path="/service/repairs" element={<ServiceRepairs />} />
            <Route path="/service/logistics" element={<ServiceLogistics />} />
            <Route path="/service/dcare" element={<ServiceDcare />} />
            <Route path="/service/lcm" element={<ServiceLCM />} />
            <Route path="/vectorworks" element={<Mainpage />} />
            <Route path="/vectorworks/Buildings" element={<Buildings />} />
            <Route path="/vectorworks/Landscap" element={<Landscap />} />
            <Route
              path="/vectorworks/Entertainments"
              element={<Entertainment />}
            />
            <Route path="/vectorworks/Training" element={<Training />} />
            <Route path="/education" element={<Educationmainpage />} />
            <Route path="/education/K12" element={<K12 />} />
            <Route path="/education/Hi-Education" element={<HiEducation />} />
            <Route path="/education/Teachers" element={<Teachers />} />
            <Route path="/education/Leaders" element={<Leaders />} />
            <Route path="/education/Parents" element={<Parents />} />
            <Route path="/education/IT" element={<IT />} />
            <Route path="/education/DCareSchool" element={<DCareSchool />} />
            <Route path="/education/DEP" element={<DEP />} />
            <Route path="/education/DTrain" element={<DTrain />} />
            <Route path="/education/CaseStudy" element={<CaseStudy />} />
            <Route path="/education/EduService" element={<ServiceEdu />} />
            <Route path="/enterprise" element={<EnterpriceMainpage />} />
            <Route path="/enterprise/start-ups" element={<StartUpsSME />} />
            <Route path="/enterprise/DEP" element={<Dep />} />
            <Route path="/enterprise/TechSeries" element={<Techseries />} />
            <Route
              path="/enterprise/large-enterprise"
              element={<LargeEnterprise />}
            />
            <Route path="/enterprise/mid-market" element={<MidMarket />} />
            <Route path="/enterprise/Bep" element={<Bep />} />
            <Route
              path="/enterprise/EnterpriseSerices"
              element={<EnterpriseSerices />}
            />
            <Route path="/enterprise/MDM" element={<MDM />} />
            <Route path="/enterprise/developers" element={<Developers />} />
            <Route path="/enterprise/abm" element={<ABM />} />
            <Route path="/enterprise/Addons" element={<AddOns />} />
            <Route path="/book-appointment" element={<AppointmentCalender />} />
            <Route path="/blog" element={<BlogHome />} />
            <Route path="/blog/:slug" element={<BlogPage />} />
            <Route path="/blog/category/:category" element={<BlogHome />} />
            <Route path="/form/IIM" element={<FormLead />} />
            <Route path="/enterprise/switch-to-mac" element={<Switcher />} />
            <Route path="/Apple/mac-does-that" element={<MacDoesThat />} />
            {/* <Route path="/enterprise/refresh-your-mac" element={<Refresh/>} /> */}
            <Route path="/profile" element={<Profile />} />
            <Route path="/skoch-report" element={<SkochReport />} />
            <Route path="/canalys-report" element={<CanalysReport />} />
            <Route path="/sitemap" element={<Sitemap />} />
            <Route path="/links" element={<LinkTree />} />
            <Route path="/bep-feedback" element={<FeedbackForm />} />
            <Route path="/contact-dts" element={<MumbaiEventContact />} />
            {/* <Route path="/gallery" element={<Gallery />} /> */}

            {/* Mailers */}
            <Route
              path="/mailers/copilot-plus-pcs"
              element={<Copilotmailer />}
            />
            <Route path="/mailers/iphone16" element={<IPhone16 />} />
            <Route path="/mailers/iphone16pro" element={<IPhone16Pro />} />
            <Route path="/mailers/ipadmini" element={<AppleiPadminiMailer />} />
            <Route path="/mailers/diwali" element={<DLDiwali />} />
            <Route
              path="/mailers/macbook-pro-m4"
              element={<AppleMacBookProM4 />}
            />

            {/* Products */}
            <Route path="/copilot+pcs" element={<CopilotPlusPCs />} />
            <Route path="/Apple/iPhone_16/" element={<IPhone16ProductPage />} />
            <Route
              path="/Apple/iPhone_16_Pro/"
              element={<IPhone16ProProductPage />}
            />
            <Route path="/apple/iPad-Pro-M4" element={<IPadProM4 />} />
            <Route
              path="/Apple/iPad/iPadAirM2"
              element={<IPadAirM2ProductPage />}
            />
            <Route path="/apple/macbook-pro-m3" element={<MacbookProM3 />} />
            <Route path="/Apple/iMac_M3/" element={<IMacM3ProductPage />} />
            <Route
              path="/Apple/Apple_Watch_Ultra_2/"
              element={<AppleWatchUltra2ProductPage />}
            />
            <Route
              path="/Apple/Apple_Watch_Series_10/"
              element={<AppleWatchSeries10ProductPage />}
            />
            <Route path="/apple/airpods/airpods_4" element={<AirPods4 />} />
            <Route
              path="/apple/airpods/airpods_Pro_2"
              element={<AirPodsPro2 />}
            />
          </Routes>
        </Suspense>
      </Router>
    </HelmetProvider>
  );
}

export default App;

function IPhone16ProProductPage() {
  return (window.location.href = "/Apple/iPhone/iPhone_16_Pro/");
}

function IPhone16ProductPage() {
  return (window.location.href = "/Apple/iPhone/iPhone_16/");
}

function IPadAirM2ProductPage() {
  return (window.location.href = "/Apple/iPad/iPadAirM2.html");
}

function IMacM3ProductPage() {
  return (window.location.href = "/Apple/iMac/iMac_M3/");
}

function AppleWatchUltra2ProductPage() {
  return (window.location.href = "/Apple/Apple_Watch/Apple_Watch_Ultra_2/");
}

function AppleWatchSeries10ProductPage() {
  return (window.location.href = "/Apple/Apple_Watch/Apple_Watch_Series_10/");
}

function AirPods4() {
  return (window.location.href = "/Apple/AirPods/AirPods_4/");
}

function AirPodsPro2() {
  return (window.location.href = "/Apple/AirPods/AirPods_Pro_2/");
}

function IPhone16() {
  return (window.location.href = "/mailers/iPhone16/");
}

function IPhone16Pro() {
  return (window.location.href = "/mailers/iPhone16Pro/");
}

function AppleiPadminiMailer() {
  return (window.location.href = "/mailers/Apple_iPad_Mini/");
}

function DLDiwali() {
  return (window.location.href = "/mailers/Diwali/");
}

function AppleMacBookProM4() {
  return (window.location.href = "/mailers/Apple_Macbook_Pro_M4/");
}
