// import React, { useState } from "react";
// import Calendar from "react-calendar";
// import "./AppointmentCalender.css";
// import "react-calendar/dist/Calendar.css";

import Cal, { getCalApi } from "@calcom/embed-react";
import { useEffect } from "react";

import Navbar from "../About-us/Navbar/navbar";
import Footer from "../../sections/Footer/Footer";

function AppointmentCalender() {
  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: "30min" });
      cal("ui", {
        cssVarsPerTheme: { light: { "cal-brand": "#d80128" } },
        hideEventTypeDetails: true,
        layout: "month_view",
      });
    })();
  }, []);
  return (
    <>
      <Navbar />
      <main
        className="mt-5 pt-5"
        style={{
          display: "grid",
          width: "100svw",
          minHeight: "100svh",
          placeItems: "center",
        }}
      >
        <Cal
          namespace="30min"
          calLink="likith-singh-w1zj8u/30min"
          style={{ width: "100%", height: "max-content", overflow: "scroll" }}
          config={{ layout: "month_view" }}
        />
      </main>
      <Footer />
    </>
  );

  // const [date, setDate] = useState(new Date(Date.now()));

  // function onChange(nextValue) {
  //   setDate(nextValue);
  // }

  // return (
  //   <div
  //     className="Calendar-container"
  //     style={{ display: "grid", placeItems: "center", minHeight: "100dvh" }}
  //   >
  //     <div>
  //       <Calendar
  //         view="month"
  //         value={date}
  //         onChange={onChange}
  //         minDate={new Date(Date.now())}
  //         tileDisabled={({ activeStartDate, date, view }) =>
  //           date.getDay() === 0 || date.getDay() === 6
  //         }
  //       />
  //       {/* <h3>
  //       {date
  //         ? date.toLocaleDateString("en-IN", {
  //             day: "numeric",
  //             month: "long",
  //             year: "numeric",
  //           })
  //         : ""}
  //     </h3> */}
  //       <h3 className="text-center mt-4">
  //         <time datetime={date ? date.toLocaleDateString("en-IN") : ""}>
  //           {date ? date.toLocaleDateString("en-IN") : ""}
  //         </time>
  //       </h3>
  //     </div>
  //   </div>
  // );
}

export default AppointmentCalender;
