import React, {useState} from 'react'
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { IoIosArrowForward } from "react-icons/io";

import { FaLinkedin } from "react-icons/fa6";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa6";

import { Accordion } from 'react-bootstrap';

import { useLocation } from 'react-router-dom';

// import { RiHomeFill } from "react-icons/ri";
import { MdKeyboardArrowRight } from "react-icons/md";
import { IoHomeOutline } from "react-icons/io5";

import "./Footer.css"


function Footer() {

    const [downloadsActive, setDownloadsActive] = useState(false)

    const location = useLocation();
    const { pathname } = location;
    const segments = pathname.split('/');

    // const splitLocation = pathname.split("/people");
    // console.log(segments[1])
    let url = '/'

  return (
    <div className='container-fluid' style={{backgroundColor:"#f5f5f5",  paddingBlock: "3rem"}}>
       {
         segments[segments.length - 1] !== '' && 
         <div className="container breadCrumContainer">
           {
             segments.map((segment, index) => {
               url += `${segment}`
               return index === segments.length - 1 ? 
                 <p key={index} className='breadCrumLink' ><MdKeyboardArrowRight /> {segment.replace(/-/g, ' ')}</p> 
                 : 
                 <HashLink key={index} to={url} className='breadCrumLink'>{ segment === '' ? <IoHomeOutline /> : <p><MdKeyboardArrowRight /> {segment.replace(/-/g, ' ')}</p>}</HashLink>
             })
           }
         </div>
       }
        <div className="container footerMain">
            <div className="AboutDL footerSubSection">
                <p>Company</p>
                <HashLink to="/" style={{color: segments[1] === '' ? '#d80128' : '#00000'}}>About Us</HashLink>
                <p><HashLink to={"/people"} style={{color: segments[1] === 'people' ? '#d80128' : '#00000'}}>Careers</HashLink><span className='bubble'><HashLink to={"/people#Openings"}>We're hiring</HashLink></span></p>
                <Link to={"https://www.shop.datalogicsindia.com/"} target='_blank' rel="noreferrer">Shop</Link>
                <HashLink to={"/contact-us"} style={{color: segments[1] === 'contact-us' ? '#d80128' : '#00000'}}>Contact Us</HashLink>
                <p>Resources</p>
                <HashLink to={"/blog"} style={{color: segments[1] === 'blog' ? '#d80128' : '#00000'}}>Blog</HashLink>
                <HashLink to={"/copilot+pcs"} style={{color: segments[1] === 'copilot+pcs' ? '#d80128' : '#00000'}}>Copilot+PCs</HashLink>
                <HashLink to={"/enterprise/switch-to-mac#TCOCalculator"} style={{color: segments[2] === 'switch-to-mac#TCOCalculator' ? '#d80128' : '#00000'}}>Calculate Mac TCO</HashLink>
                <p onClick={() => setDownloadsActive(!downloadsActive)} style={{cursor:"pointer", "--rotateIcon": downloadsActive ? "90deg" : "0deg"}} className='DownloadsLink'>Downloads <span><IoIosArrowForward /></span></p>
                <div className="footerdropdown" style={{"--rotateX": downloadsActive ? "0deg" : "90deg", "--display": downloadsActive ? "flex" : "none", "--visibility" : downloadsActive ? "visible" : "hidden"}}>
                    <HashLink to={"/profile"} style={{color: segments[1] === 'profile' ? '#d80128' : '#00000'}}>Profile</HashLink>
                    <HashLink to={"/skoch-report"} style={{color: segments[1] === 'skoch-report' ? '#d80128' : '#00000'}}>Skoch Report on TCO</HashLink>
                    <HashLink to={"/canalys-report"} style={{color: segments[1] === 'canalys-report' ? '#d80128' : '#00000'}}>Report on AI capable PCs</HashLink>
                    <HashLink to={"/enterprise/start-ups#startup-resources"} style={{color: segments[2] === 'start-ups#startup-resources' ? '#d80128' : '#00000'}}>Apple for Business</HashLink>
                </div>
            </div>
            <div className="products footerSubSection">
                <p>Shop and Learn</p>
                <HashLink to={"/Apple/macbook_pro/Macbook_Pro_M4/"} target="_blank">MacBook Pro M4 <span style={{color: "#d80128", paddingInline: "0.25rem", borderRadius: "1rem", fontSize: "0.5rem", verticalAlign: "middle"}}>New</span></HashLink>
                <HashLink to={"/Apple/mac_mini/mac_mini_M4/"} target="_blank">Mac mini M4 <span style={{color: "#d80128", paddingInline: "0.25rem", borderRadius: "1rem", fontSize: "0.5rem", verticalAlign: "middle"}}>New</span></HashLink>
                <HashLink to={"/Apple/iMac/iMac_M4/"} target="_blank">iMac M4 <span style={{color: "#d80128", paddingInline: "0.25rem", borderRadius: "1rem", fontSize: "0.5rem", verticalAlign: "middle"}}>New</span></HashLink>
                <HashLink to={"/Apple/iPad_Mini/"} target="_blank">iPad mini</HashLink>
                <HashLink to={"/Apple/iPhone/iPhone_16_Pro/"} target="_blank">iPhone 16 Pro</HashLink>
                <HashLink to={"/Apple/iPhone/iPhone_16/"} target="_blank">iPhone 16</HashLink>
                <HashLink to={"/Apple/iPad_Pro/"} target="_blank">iPad Pro M4</HashLink>
                <HashLink to={"/Apple/iPad/iPadAirM2.html"} target="_blank">iPad Air M2</HashLink>
                <HashLink to={"/Apple/Apple_Watch/Apple_Watch_Series_10/"} target="_blank">Apple Watch Series 10</HashLink>
                <HashLink to={"/Apple/AirPods/AirPods_Pro_2/"} target="_blank">AirPods Pro 2</HashLink>
                <HashLink to={"/Apple/AirPods/AirPods_4/"} target="_blank">AirPods 4</HashLink>
            </div>
            <div className="ServiceDL footerSubSection">
                <p>Service</p>
                <HashLink to={"/service"} style={{color: segments[1] === 'service' && segments[2] === undefined ? '#d80128' : '#00000'}}>Home</HashLink>
                <HashLink to={"/service/enterprise"} style={{color: segments[2] === 'enterprise' ? '#d80128' : '#00000'}}>Enterprise</HashLink>
                <HashLink to={"/service/repairs"} style={{color: segments[2] === 'repairs' ? '#d80128' : '#00000'}}>Repairs</HashLink>
                <HashLink to={"/service/logistics"} style={{color: segments[2] === 'logistics' ? '#d80128' : '#00000'}}>Logistics</HashLink>
                <HashLink to={"/service/dcare"} style={{color: segments[2] === 'dcare' ? '#d80128' : '#00000'}}>Dcare</HashLink>
                <HashLink to={"/service/dcare#DCareCoverage"} style={{color: segments[2] === 'dcare#DCareCoverage' ? '#d80128' : '#00000'}}>DCare Coverage</HashLink>
                <HashLink to={"/service/lcm"} style={{color: segments[2] === 'lcm' ? '#d80128' : '#00000'}}>LCM</HashLink>        
            </div>
            <div className="EnterpriseDL footerSubSection">
                <p>Enterprise</p>
                <HashLink to={"/enterprise"} style={{color: segments[1] === 'enterprise' && segments[2] === undefined ? '#d80128' : '#00000'}}>Home</HashLink>
                <HashLink to={"/enterprise/start-ups"} style={{color: segments[2] === 'start-ups' ? '#d80128' : '#00000'}}>SME & Start-Ups</HashLink>
                <HashLink to={"/enterprise/mid-market"} style={{color: segments[2] === 'mid-market' ? '#d80128' : '#00000'}}>Mid-Market</HashLink>
                <HashLink to={"/enterprise/large-enterprise"} style={{color: segments[2] === 'large-enterprise' ? '#d80128' : '#00000'}}>Enterprise</HashLink>
                <HashLink to={"/enterprise/developers"} style={{color: segments[2] === 'developers' ? '#d80128' : '#00000'}}>Developers</HashLink>
                <HashLink to={"/enterprise/abm"} style={{color: segments[2] === 'abm' ? '#d80128' : '#00000'}}>ABM</HashLink>
                <HashLink to={"/enterprise/switch-to-mac"} style={{color: segments[2] === 'switch-to-mac' ? '#d80128' : '#00000'}}>Switch to Mac</HashLink>
                <HashLink to={"/Apple/mac-does-that"} style={{color: segments[1] === 'mac-does-that' ? '#d80128' : '#00000'}}>Mac Does That</HashLink>
            </div>
            <div className="EducationDL footerSubSection">
                <p>Education</p>
                <HashLink to={"/education"} style={{color: segments[1] === 'education' && segments[2] === undefined ? '#d80128' : '#00000'}}>Home</HashLink>
                <HashLink to={"/education/Hi-Education"} style={{color: segments[2] === 'Hi-Education' ? '#d80128' : '#00000'}}>Hi-Education</HashLink>
                <HashLink to={"/education/teachers"} style={{color: segments[2] === 'teachers' ? '#d80128' : '#00000'}}>Teachers</HashLink>
                <HashLink to={"/education/Parents"} style={{color: segments[2] === 'Parents' ? '#d80128' : '#00000'}}>Parents</HashLink>
                <HashLink to={"/education/Leaders"} style={{color: segments[2] === 'Leaders' ? '#d80128' : '#00000'}}>Leaders</HashLink>
                <HashLink to={"/education/IT"} style={{color: segments[2] === 'IT' ? '#d80128' : '#00000'}}>IT</HashLink>
                <HashLink to={"/education/K12"} style={{color: segments[2] === 'K12' ? '#d80128' : '#00000'}}>K12</HashLink>
                <HashLink to={"/education/CaseStudy"} style={{color: segments[2] === 'CaseStudy' ? '#d80128' : '#00000'}}>Case Study</HashLink>
                <HashLink to={"/education/eduService"} style={{color: segments[2] === 'eduService' ? '#d80128' : '#00000'}}>Service</HashLink>
                <HashLink to={"/education/DEP"} style={{color: segments[2] === 'DEP' ? '#d80128' : '#00000'}}>DEP</HashLink>
                <HashLink to={"/education/DTrain"} style={{color: segments[2] === 'DTrain' ? '#d80128' : '#00000'}}>DTrain</HashLink>
                <HashLink to={"/education/DCareSchool"} style={{color: segments[2] === 'DCareSchool' ? '#d80128' : '#00000'}}>DCare School</HashLink>
            </div>
            <div className="VectorworksDL footerSubSection">
                <p>For Creators</p>
                <HashLink to={"/vectorworks"} style={{color: segments[1] === 'vector' && segments[2] === undefined ? '#d80128' : '#00000'}}>Home</HashLink>
                <HashLink to={"/vectorworks/Buildings"} style={{color: segments[2] === 'Buildings' ? '#d80128' : '#00000'}}>Buildings</HashLink>
                <HashLink to={"/vectorworks/Landscap"} style={{color: segments[2] === 'Landscap' ? '#d80128' : '#00000'}}>Landscapes</HashLink>
                <HashLink to={"/vectorworks/Entertainments"} style={{color: segments[2] === 'Entertainments' ? '#d80128' : '#00000'}}>Entertainment</HashLink>
                <HashLink to={"/vectorworks#AllProducts"} style={{color: segments[1] === 'vector#AllProducts' ? '#d80128' : '#00000'}}>All Products</HashLink>
                <HashLink to={"/vectorworks/Training"} style={{color: segments[2] === 'Training' ? '#d80128' : '#00000'}}>Training</HashLink>
                <HashLink to={"/vectorworks#Enquiry"} style={{color: segments[1] === 'vector#Enquiry' ? '#d80128' : '#00000'}}>Enquiry</HashLink>
            </div>
        </div>
        <div className="footerMainSM">
            <Accordion className='footerSubSection'>
                <Accordion.Item eventKey="0" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true">
                    <Accordion.Header>Company</Accordion.Header>
                    <Accordion.Body>
                            <HashLink to="/">About Us</HashLink>
                            <p><HashLink to={"/people"}>Careers</HashLink><span className='bubble'><HashLink to={"/people#Openings"}>We're hiring</HashLink></span></p>
                            <Link to={"https://www.shop.datalogicsindia.com/"} target='_blank' rel="noreferrer">Shop</Link>
                            <HashLink to={"/contact-us"}>Contact Us</HashLink>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="25">
                    <Accordion.Header>Service</Accordion.Header>
                    <Accordion.Body>
                        <HashLink to={"/service"}>Home</HashLink>
                        <HashLink to={"/service/enterprise"}>Enterprise</HashLink>
                        <HashLink to={"/service/repairs"}>Repairs</HashLink>
                        <HashLink to={"/service/logistics"}>Logistics</HashLink>
                        <HashLink to={"/service/dcare"}>Dcare</HashLink>
                        <HashLink to={"/service/dcare#DCareCoverage"}>DCare Coverage</HashLink>
                        <HashLink to={"/service/lcm"}>LCM</HashLink> 
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="50">
                    <Accordion.Header>Enterprise</Accordion.Header>
                    <Accordion.Body>
                        <HashLink to={"/enterprise"}>Home</HashLink>
                        <HashLink to={"/enterprise/start-ups"}>SME & Start-Ups</HashLink>
                        <HashLink to={"/enterprise/mid-market"}>Mid-Market</HashLink>
                        <HashLink to={"/enterprise/large-enterprise"}>Enterprise</HashLink>
                        <HashLink to={"/enterprise/developers"}>Developers</HashLink>
                        <HashLink to={"/enterprise/abm"}>ABM</HashLink>
                        <HashLink to={"/enterprise/switch-to-mac"}>Switch to Mac</HashLink>
                        <HashLink to={"/Apple/mac-does-that"}>Mac Does That</HashLink>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="75">
                    <Accordion.Header>Education</Accordion.Header>
                    <Accordion.Body>
                        <HashLink to={"/education"}>Home</HashLink>
                        <HashLink to={"/education/Hi-Education"}>Hi-Education</HashLink>
                        <HashLink to={"/education/teachers"}>Teachers</HashLink>
                        <HashLink to={"/education/Parents"}>Parents</HashLink>
                        <HashLink to={"/education/Leaders"}>Leaders</HashLink>
                        <HashLink to={"/education/IT"}>IT</HashLink>
                        <HashLink to={"/education/K12"}>K12</HashLink>
                        <HashLink to={"/education/CaseStudy"}>Case Study</HashLink>
                        <HashLink to={"/education/eduService"}>Service</HashLink>
                        <HashLink to={"/education/DEP"}>DEP</HashLink>
                        <HashLink to={"/education/DTrain"}>DTrain</HashLink>
                        <HashLink to={"/education/DCareSchool"}>DCare School</HashLink>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="100">
                    <Accordion.Header>For Creators</Accordion.Header>
                    <Accordion.Body>
                        <HashLink to={"/vectorworks"}>Home</HashLink>
                        <HashLink to={"/vectorworks/Buildings"}>Buildings</HashLink>
                        <HashLink to={"/vectorworks/Landscap"}>Landscapes</HashLink>
                        <HashLink to={"/vectorworks/Entertainments"}>Entertainment</HashLink>
                        <HashLink to={"/vectorworks#AllProducts"}>All Products</HashLink>
                        <HashLink to={"/vectorworks/Training"}>Training</HashLink>
                        <HashLink to={"/vectorworks#Enquiry"}>Enquiry</HashLink>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="125">
                    <Accordion.Header>Resources</Accordion.Header>
                    <Accordion.Body>
                        <HashLink to={"/blog"}>Blog</HashLink>
                        <HashLink to={"/copilot+pcs"}>Copilot+PCs</HashLink>
                        <HashLink to={"/enterprise/switch-to-mac#TCOCalculator"}>Calculate Mac TCO</HashLink>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="150">
                    <Accordion.Header>Downloads</Accordion.Header>
                    <Accordion.Body>
                        <HashLink to={"/profile"}>Profile</HashLink>
                        <HashLink to={"/skoch-report"}>Skoch Report on TCO</HashLink>
                        <HashLink to={"/canalys-report"}>Report on AI capable PCs</HashLink>
                        <HashLink to={"/enterprise/start-ups#startup-resources"}>Apple for Business</HashLink>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7" data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-delay="175">
                    <Accordion.Header>Shop and Learn</Accordion.Header>
                    <Accordion.Body>
                        <HashLink to={"/Apple/macbook_pro/Macbook_Pro_M4/"} target="_blank">MacBook Pro M4 <span style={{color: "#d80128", paddingInline: "0.25rem", borderRadius: "1rem", fontSize: "0.5rem", verticalAlign: "middle"}}>New</span></HashLink>
                        <HashLink to={"/Apple/iMac/iMac_M4/"} target="_blank">iMac M4 <span style={{color: "#d80128", paddingInline: "0.25rem", borderRadius: "1rem", fontSize: "0.5rem", verticalAlign: "middle"}}>New</span></HashLink>
                        <HashLink to={"/Apple/mac_mini/mac_mini_M4/"} target="_blank">Mac mini M4 <span style={{color: "#d80128", paddingInline: "0.25rem", borderRadius: "1rem", fontSize: "0.5rem", verticalAlign: "middle"}}>New</span></HashLink>
                        <HashLink to={"/Apple/iPad_Mini/"} target="_blank">iPad mini</HashLink>
                        <HashLink to={"/Apple/iPhone/iPhone_16_Pro/"} target="_blank">iPhone 16 Pro</HashLink>
                        <HashLink to={"/Apple/iPhone/iPhone_16/"} target="_blank">iPhone 16</HashLink>
                        <HashLink to={"/Apple/iPad_Pro/"} target="_blank">iPad Pro M4</HashLink>
                        <HashLink to={"/Apple/iPad/iPadAirM2.html"} target="_blank">iPad Air M2</HashLink>
                        <HashLink to={"/Apple/Apple_Watch/Apple_Watch_Series_10/"} target="_blank">Apple Watch Series 10</HashLink>
                        <HashLink to={"/Apple/AirPods/AirPods_Pro_2/"} target="_blank">AirPods Pro 2</HashLink>
                        <HashLink to={"/Apple/AirPods/AirPods_4/"} target="_blank">AirPods 4</HashLink>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
        <div className="container footerLine"></div>
        <div className="container footerBottom">
           <div className="copyright"><p>Copyright © 2024 Datalogics India. All rights reserved.</p></div>
           <div className="FooterLinks">
                <p><Link to="/privacy-policy" style={{color: segments[1] === 'privacy-policy' ? '#d80128' : '#00000'}}>Privacy Policy</Link> | <HashLink to={"/sitemap.xml"} target='_blank'>Sitemap</HashLink></p>
                <div className="socialLinks">
                    <Link className="nav-link" to="https://www.linkedin.com/company/datalogics-india/mycompany/" target="_blank" rel="noreferrer"><FaLinkedin /></Link>
                    <Link className="nav-link" to="https://www.facebook.com/people/Datalogics-India-Private-Limited/100086091872602/" target="_blank" rel="noreferrer"><FaSquareFacebook /></Link>
                    <Link className="nav-link" to="https://www.instagram.com/datalogics_india_pvt_ltd/" target="_blank" rel="noreferrer"><FaSquareInstagram /></Link>
                    <Link className="nav-link" to="https://x.com/Datalogics_IN" target="_blank" rel="noreferrer"><FaSquareXTwitter /></Link>
                    <Link className="nav-link" to="http://www.youtube.com/@DatalogicsIndia" target="_blank" rel="noreferrer"><FaYoutube /></Link>
                </div>
            </div> 
        </div>
    </div>
  )
}

export default Footer