import React from "react";
import Navbar from "../About-us/Navbar/navbar";
import Floating from "../Floating icon/floating";
// import Footer from '../pages/vector/commonfooter/footer';
import Footer from "../../sections/Footer/Footer";
import "./Profile.css";
import ContactUsForm from "../SwitchToMac/ContactUsForm";
import PDFViewer from "../../sections/PDFViewer/PDFViewer";
import Breadcrum from "../../sections/Breadcrum/Breadcrum";

import DLProfile from "../../assets/data/DL_Profile_Edu.pdf";
import { Helmet } from "react-helmet-async";

function EduProfile() {
  return (
    <>
      <Helmet>
        <title>
          Datalogics Company Profile an Apple Authorized Education Specialist
        </title>
        <meta
          name="description"
          content="Explore Datalogics India's education-focused company profile and our role as an Apple Authorized Education Specialist. Learn how we help schools and colleges integrate iPads, MacBooks, and Apple technology to enhance teaching and learning."
        />
        <link
          rel="canonical"
          href={"https://www.datalogicsindia.com/education-profile"}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            "Datalogics India | Trusted Apple Education Partner for Schools & Colleges"
          }
        />
        <meta
          property="og:url"
          content={"https://www.datalogicsindia.com/education-profile"}
        />
        <meta
          property="og:site_name"
          content="Datalogics India | Trusted Apple Education Partner for Schools & Colleges"
        />
        <meta
          name="twitter:title"
          content={`Datalogics India | Trusted Apple Education Partner for Schools & Colleges`}
        />
        <meta name="twitter:site" content="@datalogicsindia" />
        <meta name="twitter:creator" content="@Datalogics_IN" />
      </Helmet>
      <Navbar />
      <Floating />
      <div className="container mt-5">
        <div className="row pt-3 pt-lg-5">
          <Breadcrum textColor={"#000000"} />
        </div>
      </div>
      <PDFViewer
        doc={DLProfile}
        title="Education Company Profile"
        filename="Datalogics Company Profile | Education"
      />
      <ContactUsForm
        ctaMain="Contact us!"
        ctaPara="Explore and find the most effective solution tailored to meet the unique needs of your business."
        subject="Education Company Profile page Form Response"
      />
      <Footer />
    </>
  );
}

export default EduProfile;
