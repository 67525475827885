import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useLocation } from "react-router-dom";

function Breadcrum({ textColor }) {
  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split("/").filter(Boolean);

  const hex2rgb = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  return (
    <div className="container">
      <Breadcrumb
        style={{
          placeSelf: "flex-start",
          color: hex2rgb(textColor, 1),
          "--bs-secondary-color": hex2rgb(textColor, 0.7),
          textWrap: "nowrap",
          flexWrap: "nowrap",
          overflow: "hidden",
        }}
      >
        <Breadcrumb.Item
          href="/"
          style={{ color: hex2rgb(textColor, 1), maxWidth: "75svw" }}
        >
          Home
        </Breadcrumb.Item>
        {segments.map((segment, index) => {
          const displayText =
            (segment.replace(/-/g, " ") || "Home").charAt(0).toUpperCase() +
            (segment.replace(/-/g, " ") || "Home").slice(1);
          const isLastSegment = index === segments.length - 1;
          const isCategory = displayText === "Category";

          return isLastSegment || isCategory ? (
            <Breadcrumb.Item
              key={index}
              active={isLastSegment}
              style={{
                textDecoration: isLastSegment
                  ? `underline ${hex2rgb(textColor, 0.5)}`
                  : "none",
                color: hex2rgb(textColor, 0.7),
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "75svw",
                overflow: "hidden",
                textWrap: "nowrap",
              }}
            >
              {displayText === "EduService"
                ? "Service"
                : displayText === "Large enterprise"
                ? "Enterprise"
                : displayText}
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item
              key={index}
              href={`/${segments.slice(0, index + 1).join("/")}`}
              style={{
                color: hex2rgb(textColor, 1),
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "75svw",
                overflow: "hidden",
                textWrap: "nowrap",
              }}
            >
              {displayText}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    </div>
  );
}

export default Breadcrum;
